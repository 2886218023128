import React from 'react';

const Poster = () => {
  return (
    <div className="relative h-screen flex items-center justify-center bg-black">
      <div className="w-full h-full flex items-center justify-center">
        <img
          src="/cricketposter.jpg"
          alt=""
          className="md:max-w-[90%] md:max-h-[100%] object-cover"
        />
      </div>
      <div className="absolute flex flex-col items-center justify-center bottom-40 md:bottom-16 z-10">
        <a href="https://wa.link/ggnow">
          <button className="bg-gray-900 animate-bounce font-semibold uppercase rounded-md px-6 py-2 md:px-10 md:py-3 text-white  mt-4 text-sm md:text-lg">
            Claim Now
          </button>
        </a>
      </div>
    </div>
  );
};

export default Poster;
